import React from 'react'

export default function Footer() {
    return (
        <footer>
            <p>
               Made by Alexander Koch
            </p>
        </footer>
    )
}
