// src/App.js
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Home from './views/pages/Home/Home';
import Login from './views/pages/Login/Login';
import Dashboard from './views/pages/Dashboard/Dashboard';

import DashboardMail from './views/pages/Dashboard/Mail/Mail';

import DashboardClient from './views/pages/Dashboard/Client/Client';
import DashboardUser from './views/pages/Dashboard/User/User';
import DashboardProfile from './views/pages/Dashboard/Profile/Profile';

import Logout from "./views/pages/Logout/Logout";
import SiteNotFound from "./views/pages/SiteNotFound/SiteNotFound";

import Header from "./views/layouts/Header";
import Footer from "./views/layouts/Footer";
import {useAuth} from "./hooks/useAuth";

function App() {

    const {user} = useAuth();

    return (
        <>
            <Header/>
            <div id={"rootApp"}>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/login" element={!user ? <Login/> : <Dashboard/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/dashboard" element={user ? <Dashboard/> : <Login/>}>
                        <Route path="mail" element={<DashboardMail/>}/>
                        <Route path="client" element={<DashboardClient/>}/>
                        <Route path="profile" element={<DashboardProfile/>}/>
                        {(user?.role === 'admin' || user?.role === 'root') && (
                            <Route path="user" element={<DashboardUser/>}/>
                        )}
                    </Route>
                    <Route path="*" element={<SiteNotFound/>}/>
                </Routes>
            </div>
            <Footer/>
        </>
    );
}

export default App;
