// src/store/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: JSON.parse(localStorage.getItem('user')) || null,
    token: localStorage.getItem('token') || null,
    sessionToken: localStorage.getItem('sessionToken') || null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess(state, action) {
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.sessionToken = action.payload.sessionToken;
            localStorage.setItem('user', JSON.stringify(state.user));
            localStorage.setItem('token', state.token);
            localStorage.setItem('sessionToken', state.sessionToken);
        },
        logout(state) {
            state.user = null;
            state.token = null;
            state.sessionToken = null;
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('sessionToken');
        },
    },
});

export const { loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
