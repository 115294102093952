// src/hooks/useAuth.js
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, logout } from '../store/authSlice';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axiosInstance from "../middleware/axiosInstance";

export const useAuth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, token } = useSelector((state) => state.auth);

    useEffect(() => {
        const authenticate = async () => {
            const storedToken = localStorage.getItem('token');
            const storedSessionToken = localStorage.getItem('sessionToken');
            const lastFetched = localStorage.getItem('lastFetched'); // store last fetch time

            // Check if we need to refresh based on time or other criteria
            const shouldFetch = !lastFetched || Date.now() - lastFetched > 30 * 1000; // e.g., 15 minutes

            if (storedToken && storedSessionToken && shouldFetch) {
                try {
                    const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/auth/me`, {
                        headers: { Authorization: `Bearer ${storedToken}`, 'Session-Token': storedSessionToken },
                    });
                    console.log("fetched")
                    localStorage.setItem('lastFetched', Date.now()); // Update last fetched time
                    dispatch(loginSuccess({ user: response.data, token: storedToken, sessionToken: storedSessionToken }));
                } catch (error) {
                    console.log(error);
                    localStorage.removeItem('token');
                    localStorage.removeItem('sessionToken');
                    localStorage.removeItem('user');
                    if (window.location.pathname.includes("dashboard")) {
                        navigate('/login');
                    }
                }
            } else if (!storedToken || !storedSessionToken) {
                if (window.location.pathname.includes("dashboard")) {
                    navigate('/login');
                }
            }
        };

        authenticate();
    }, [dispatch, navigate]);

    const login = async (username, password) => {
        try {
            const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                username,
                password,
            });

            const { token, sessionToken } = response.data;

            const userResponse = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/auth/me`, {
                headers: { Authorization: `Bearer ${token}`, 'Session-Token': sessionToken },
            });

            localStorage.setItem('token', token);
            localStorage.setItem('sessionToken', sessionToken);
            dispatch(loginSuccess({ user: userResponse.data, token, sessionToken }));
            navigate('/dashboard');
        } catch (error) {
            console.error('Login failed: ', error.response ? error.response.data : error.message);
        }
    };

    const logoutUser = async () => {
        const storedToken = localStorage.getItem('token');
        const storedSessionToken = localStorage.getItem('sessionToken');

        try {
            await axiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/logout`, {}, {
                headers: { Authorization: `Bearer ${storedToken}`, 'Session-Token': storedSessionToken },
            });
        } catch (error) {
            console.error('Logout failed: ', error.response ? error.response.data : error.message);
        }

        localStorage.removeItem('token');
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('user');
        dispatch(logout());
        navigate('/login');
    };

    // Function to fetch user data manually
    const fetchUser = async () => {
        const storedToken = localStorage.getItem('token');
        const storedSessionToken = localStorage.getItem('sessionToken');

        if (storedToken && storedSessionToken) {
            try {
                const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/auth/me`, );
                localStorage.setItem('lastFetched', Date.now()); // Update last fetched time
                dispatch(loginSuccess({ user: response.data, token: storedToken, sessionToken: storedSessionToken }));
                console.log(response.data)
            } catch (error) {
                console.log(error);
                // If token or session is invalid, remove it from localStorage and redirect to login
                localStorage.removeItem('token');
                localStorage.removeItem('sessionToken');
                localStorage.removeItem('user');
                navigate('/login');
            }
        } else {
            navigate('/login');
        }
    };

    return {
        user,
        token,
        login,
        logout: logoutUser,
        fetchUser,
    };
};
