
import React, {useEffect} from 'react'
import { useAuth } from '../../../hooks/useAuth';
import {useNavigate} from "react-router-dom";

function Logout() {

    const navigate = useNavigate();

    const { logout } = useAuth();

    useEffect(() => {
        logout().then(()=>{
            navigate("/");
        })
    }, []);


    return (
        <div>Logout</div>
    )
}

export default Logout
