// src/components/DynamicModalForm.js
import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const DynamicModalForm = ({ show, handleClose, handleSubmit, handleChange, entity = {}, title, fields, isEdit }) => {
    const renderField = (field) => {
        switch (field.type) {
            case 'text':
            case 'email':
            case 'password':
            case 'url':
            case 'date':
                return (
                    <Form.Group key={field.name}>
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control
                            type={field.type}
                            name={field.name}
                            value={entity[field.name] || ''}
                            onChange={handleChange}
                            required={field.required}
                        />
                    </Form.Group>
                );
            case 'select':
                return (
                    <Form.Group key={field.name}>
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control
                            as="select"
                            name={field.name}
                            value={entity[field.name] || ''}
                            onChange={handleChange}
                            required={field.required}
                        >
                            {field.options.map(option => (
                                <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                );
            case 'textarea':
                return (
                    <Form.Group key={field.name}>
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control
                            as="textarea"
                            name={field.name}
                            value={entity[field.name] || ''}
                            onChange={handleChange}
                            rows={field.rows || 3}
                            required={field.required}
                        />
                    </Form.Group>
                );
            default:
                return null;
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    {fields.map(renderField)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary">
                        {isEdit ? 'Update' : 'Create'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default DynamicModalForm;
