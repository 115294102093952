// src/pages/Mail.js
import React, { useState, useEffect } from 'react';
import { useAuth } from "../../../../hooks/useAuth";
import axiosInstance from "../../../../middleware/axiosInstance";
import DataTable from "../../../components/DataTable";
import DynamicModalForm from "../../../components/DynamicModalForm";
import DynamicModalDetails from "../../../components/DynamicModalDetails";

const Mail = () => {
    const { token } = useAuth();
    const [mails, setMails] = useState([]);
    const [selectedMail, setSelectedMail] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const fetchMails = async () => {
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/mail`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMails(response.data);
        } catch (error) {
            console.error('Error fetching mails:', error);
        }
    };

    useEffect(() => {
        fetchMails();
    }, [token]);

    const mailFields = [
        { name: 'subject', label: 'Subject', type: 'text', required: true },
        { name: 'fromEmail', label: 'From Email', type: 'email', required: true },
        { name: 'toEmail', label: 'To Email', type: 'email', required: true },
        { name: 'cc', label: 'CC', type: 'email' },
        { name: 'bcc', label: 'BCC', type: 'email' },
        { name: 'sendDate', label: 'Send Date', type: 'date', required: true },
        { name: 'body', label: 'Body', type: 'textarea', rows: 10, required: true },
        { name: 'frequency', label: 'Frequency', type: 'select', options: [
                { value: 'none', label: 'None' },
                { value: 'daily', label: 'Daily' },
                { value: 'weekly', label: 'Weekly' },
                { value: 'monthly', label: 'monthly' },
            ]},
        { name: 'status', label: 'Status', type: 'select', options: [
                { value: 'draft', label: 'Draft' },
                { value: 'sent', label: 'Sent' },
                { value: 'scheduled', label: 'Scheduled' },
            ]},
        { name: 'attachments', label: 'Attachments', type: 'text' }
    ];

    const handleCreate = () => {
        setSelectedMail({});
        setIsEdit(false);
        setShowModal(true);
    };

    const handleEdit = (mail) => {
        setSelectedMail(mail);
        setIsEdit(true);
        setShowModal(true);
    };

    const handleDetails = (mail) => {
        setSelectedMail(mail);
        setShowDetails(true);
    };

    const handleDelete = async (mail) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete the mail "${mail.subject}"?`);

        if (isConfirmed) {
            await axiosInstance.delete(`/mail/${mail.id}`, mail);
        }
    };

    const handleSend = async (mail) => {
        const isConfirmed = window.confirm(`Are you sure you want to send the mail "${mail.subject}"?`);

        if (isConfirmed) {
            await axiosInstance.post(`/mail/${mail.id}/send`, mail);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEdit) {
                await axiosInstance.put(`/mail/${selectedMail.id}`, selectedMail, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            } else {
                await axiosInstance.post('/mail', selectedMail, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
            setShowModal(false);
            fetchMails();
        } catch (error) {
            console.error('Error saving mail:', error);
        }
    };

    const handleChange = (e) => {
        setSelectedMail({
            ...selectedMail,
            [e.target.name]: e.target.value,
        });
    };

    const columns = [
        { header: 'Subject', accessor: mail => mail.subject },
        { header: 'From Email', accessor: mail => mail.fromEmail },
        { header: 'To Email', accessor: mail => mail.toEmail },
        { header: 'Status', accessor: mail => mail.status },
        { header: 'Actions', accessor: mail => (
                <div className={"tableActions"}>
                    <button type="button" className={"btn btn-primary"} onClick={() => handleSend(mail)}>Send</button>
                    <button type="button" className={"btn btn-info"} onClick={() => handleDetails(mail)}>Details</button>
                    <button type="button" className={"btn btn-warning"} onClick={() => handleEdit(mail)}>Edit</button>
                    <button type="button" className={"btn btn-danger"} onClick={() => handleDelete(mail)}>Delete</button>
                </div>
            )
        },
    ];

    return (
        <div>
            <button onClick={handleCreate}>Create New Mail</button>
            <DataTable
                data={mails}
                columns={columns}
                actionLink="/dashboard/mail/details"
                onReload={fetchMails}
            />
            <DynamicModalForm
                show={showModal}
                handleClose={() => setShowModal(false)}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                entity={selectedMail}
                title={isEdit ? 'Edit Mail' : 'Create Mail'}
                fields={mailFields}
                isEdit={isEdit}
            />
            <DynamicModalDetails
                show={showDetails}
                handleClose={() => setShowDetails(false)}
                entity={selectedMail}
                title="Mail Details"
                fields={mailFields}
            />
        </div>
    );
};

export default Mail;
