import React, {useEffect, useState} from 'react'
import {generateUUID} from "../../utils/generateUUID";
import {useAuth} from "../../hooks/useAuth";
import {Link} from "react-router-dom";

import themeDark from "../../images/themes/dark/theme.png"
import themeLight from "../../images/themes/light/theme.png"

import logoDark from "../../images/themes/dark/logo.png"
import logoLight from "../../images/themes/light/logo.png"

export default function Header() {

    const {user} = useAuth();
    const root = document.getElementById("root");

    const globalLinks = [{
        "link": "/",
        "title": "Home"
    }]

    const loggedOutLinks = [{
        "link": "/login",
        "title": "Login"
    },]

    const loggedInLinks = [{
        "link": "/dashboard",
        "title": "Dashboard"
    },{
        "link": "/logout",
        "title": "Logout"
    },]

    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light-mode');

    useEffect(() => {
        document.getElementById('root').className = theme;
        localStorage.setItem('theme', theme);
    }, [theme]);

    const toggleTheme = () => {
        setTheme(prevTheme => prevTheme === 'light-mode' ? 'dark-mode' : 'light-mode');
    };

    return (
        <header>
            <div className={"leftContainer"}>
                <Link to="/" className={"logoContainer"}>
                    {theme === "light-mode" &&
                        <img src={logoLight} alt="White Logo"/> ||
                        <img src={logoDark} alt="Black Logo"/>
                    }
                    <h1>
                        UXNETWORK SERVICE
                    </h1>
                </Link>
                <div onClick={toggleTheme} className={"toggleTheme"}>
                    {theme === "light-mode" &&
                        <img src={themeLight} alt="sun"/> ||
                        <img src={themeDark} alt="moon"/>
                    }
                </div>
            </div>
            <div>
                {
                    globalLinks.map((link) => (
                        <Link to={link.link} key={generateUUID()} title={link.title}>
                            <button>{link.title}</button>
                        </Link>
                    ))
                }
                {
                    user !== null && loggedInLinks.map((link) => (
                        <Link to={link.link} key={generateUUID()} title={link.title}>
                            <button>{link.title}</button>
                        </Link>
                    )) || loggedOutLinks.map((link) => (
                        <Link to={link.link} key={generateUUID()} title={link.title}>
                            <button>{link.title}</button>
                        </Link>
                    ))
                }
            </div>
        </header>
    )
}
