// src/components/DynamicModalDetails.js
import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const DynamicModalDetails = ({ show, handleClose, entity = {}, title, fields }) => {
    const renderField = (field) => {
        return (
            <Form.Group key={field.name}>
                <Form.Label>{field.label}:</Form.Label>
                <div className="form-control-plaintext">
                    {entity[field.name] || 'N/A'}
                </div>
            </Form.Group>
        );
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {fields.map(renderField)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DynamicModalDetails;
