import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useAuth } from "../../../hooks/useAuth";

const Dashboard = () => {
    const { user } = useAuth();

    return (
        <div id={"Dashboard"}>
            <div className={"sidebar"}>
                <h1>Dashboard</h1>
                <nav>
                    <ul>
                        <li><Link to="/dashboard/profile">Mein Profil</Link></li>
                        <li><Link to="/dashboard/mail">Mails</Link></li>
                        <li><Link to="/dashboard/client">Clients</Link></li>
                        {(user?.role === 'admin' || user?.role === 'root') && (
                            <li><Link to="/dashboard/user">User Management</Link></li>
                        )}
                    </ul>
                </nav>
            </div>
            <div className={"container"}>
                <Outlet /> {/* This renders the nested routes */}
            </div>
        </div>
    );
};

export default Dashboard;
