// src/pages/Home.js
import React from 'react';

const Home = () => {

    return (
        <div id={"Home"}>
            <h1>Willkommen bei Service.UXNetwork.eu!</h1>
        </div>
    );
};

export default Home;
