// src/api/axiosInstance.js
import axios from 'axios';
import store from '../store/store'; // Import your Redux store

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const state = store.getState();
        const { token, sessionToken } = state.auth;

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        if (sessionToken) {
            config.headers['Session-Token'] = sessionToken;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
